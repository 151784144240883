/*====================================================
    about-area
 ====================================================*/
.about-content {
  @media #{$tab_device} {
    margin-bottom: 50px;
    margin-top: 50px;
    padding-left: 0 !important;
  }
  @media #{$large_mobile} {
    margin-bottom: 50px;
    margin-top: 50px;
    padding-left: 0 !important;
  }
  @media #{$small_mobile} {
    margin-bottom: 50px;
    margin-top: 50px;
    padding-left: 0 !important;
  }
}
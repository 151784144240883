/*====================================================
    cta-area
 ====================================================*/
.cta-area {
  background-size: cover;
  background-position: center;
  position: relative;
  .btn-box {
    @media #{$tab_device} {
      padding-top: 20px;
    }
    @media #{$large_mobile} {
      padding-top: 20px;
    }
    @media #{$small_mobile} {
      padding-top: 20px;
    }
    &.text-right {
      @media #{$tab_device} {
        text-align: center !important;
      }
      @media #{$large_mobile} {
        text-align: center !important;
      }
      @media #{$small_mobile} {
        text-align: center !important;
      }
    }
  }
}
.cta-bg {
  background-image: url("../images/cta-bg.jpg");
}
/*====== cta-content-box ======*/
.cta-content-box {
  position: relative;
  z-index: 1;
  @include border-radius(8px);
  background-color: $white;
  @include box-shadow(0 10px 40px rgba(82, 85, 90, 0.1));
}
.cta-content {
  padding: 40px 40px 40px 20px;
  @media #{$tab_device} {
    flex-direction: column;
  }
  @media #{$large_mobile} {
    flex-direction: column;
  }
  @media #{$small_mobile} {
    flex-direction: column;
  }
  .subscribe-btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    @include box-shadow(none);
    @media #{$small_mobile_two} {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      @media #{$small_mobile_two} {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }
  .form-box {
    @media #{$tab_device} {
      margin-top: 20px;
      width: 100%;
    }
    @media #{$large_mobile} {
      margin-top: 20px;
      width: 100%;
    }
    @media #{$small_mobile} {
      margin-top: 20px;
      width: 100%;
    }
  }
}

.cta-content-top-negative {
  margin-top: -40px;
}
/*==== cta-img ====*/
.cta-img {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  position: relative;
  @media #{$tab_device} {
    @include border-radius(8px);
  }
  @media #{$large_mobile} {
    @include border-radius(8px);
  }
  @media #{$small_mobile} {
    @include border-radius(8px);
  }
  img {
    width: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    object-fit: cover;
    height: 365px;
    @media #{$tab_device} {
       @include border-radius(8px 8px 50px 50px);
    }
    @media #{$large_mobile} {
       @include border-radius(8px 8px 50px 50px);
    }
    @media #{$small_mobile} {
       @include border-radius(8px 8px 50px 50px);
    }
  }
  .cta-img-shape {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    @media #{$tab_device} {
      display: none;
    }
    @media #{$large_mobile} {
      display: none;
    }
    @media #{$small_mobile} {
      display: none;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
/*==== cta-content-box-2 ====*/
.cta-content-box-2 {
  background-color: transparent;
  @include border-radius(0);
  @include box-shadow(none);
  .cta-img {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    img {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}


/*===== mobile area =====*/
.mobile-app-content {
  @media #{$tab_device} {
    margin-bottom: 50px;
  }
  @media #{$large_mobile} {
    margin-bottom: 50px;
  }
  @media #{$small_mobile} {
    margin-bottom: 50px;
  }
  .info-list {
    li {
      color: $theme-color;
      font-weight: $font-weight-semi-bold;
      .icon {
        margin-right: 10px;
        width: 40px;
        height: 40px;
        line-height: 42px;
        font-size: $font-size-20;
      }
    }
  }
}
.mobile-img {
  position: relative;
}
.map-marker {
  position: absolute;
  width: 70px;
  height: 70px;
  @include border-radius(50%);
  background-color: $white;
  padding: 4px;
  @media #{$large_mobile_three} {
    width: 40px;
    height: 40px;
  }
  img {
    @include border-radius(50%);
  }
  &:after {
    top: 67px;
    left: 50%;
    @include transform(translateX(-50%));
    border: solid transparent;
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid $white;
    @media #{$large_mobile_three} {
      top: 40px;
    }
  }
}
.map-marker-1 {
  left: 50%;
  top: 30%;
}
.map-marker-2 {
  left: 38%;
  bottom: 22%;
  -webkit-animation-delay: .2s;
  -moz-animation-delay: .2s;
  animation-delay: .2s;
}
.map-marker-3 {
  right: 15%;
  bottom: 55%;
  -webkit-animation-delay: .4s;
  -moz-animation-delay: .4s;
  animation-delay: .4s;
}
.map-marker-4 {
  left: 12%;
  top: 30%;
  -webkit-animation-delay: .6s;
  -moz-animation-delay: .6s;
  animation-delay: .6s;
}
.map-marker-5 {
  left: 30%;
  top: 19%;
  -webkit-animation-delay: .8s;
  -moz-animation-delay: .8s;
  animation-delay: .8s;
}
.map-marker-6 {
  right: 35%;
  top: -4%;
  -webkit-animation-delay: .10s;
  -moz-animation-delay: .10s;
  animation-delay: .10s;
}

.map-pin-bg {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  @include transform(translateX(-50%) rotateX(55deg));
  top: 74px;
  @media #{$large_mobile_three} {
    top: 44px;
  }
}
/*===== modal =====*/
.modal-form {
  .modal-dialog.modal-lg {
    @media #{$extra-device4} {
      width: 500px;
    }
    @media #{$large_mobile_three} {
      width: auto;
    }
  }
  .modal-top {
    border-bottom: 1px solid $color;
    margin-bottom: 38px;
    padding: 25px 30px 23px 30px;
    .close-arrow {
      opacity: 1;
      span {
        font-size: $font-size-30;
        color: $theme-color;
        @include transition(0.3s);
        &:hover {
          color: $theme-color-2;
          @include transform(rotate(90deg));
        }
      }
    }
    .modal-title {
      font-size: $font-size-20;
      color: $theme-color;
      @media #{$small_mobile_two} {
        font-size: $font-size-16;
      }
      span {
        color: $theme-color-4;
        font-size: $font-size-30;
      }
    }
    .modal-sub {
      font-weight: $font-weight-semi-bold;
    }
  }
  .alert-content {
    .warning-icon {
      color: $color-5;
      font-size: $font-size-70;
      display: inline-block;
    }
    .modal-title {
      line-height: 25px;
      font-weight: $font-weight-semi-bold;
    }
  }
  .contact-form-action {
    padding: 0 30px 30px 30px;
  }

  .btn-box {
    .theme-btn {
      line-height: 40px;
    }
  }
  .btn-box.text-right {
    @media #{$small_mobile_two} {
      text-align: left;
    }
  }
}

.modal-container.fade {
  .modal-dialog {
    @include transform(scale(.8) translate(0, 0));
    @include transition(0.2s)
  }
}
/* modal container */
.modal-container {
  .modal-content {
    @include border-radius(8px);
  }
  .modal-header {
    padding: 18px 25px;
    border-bottom: none;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    border-top-left-radius: calc(8px - 1px);
    border-top-right-radius: calc(8px - 1px);
    &:after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $theme-color;
      opacity: 0.8;
      z-index: -1;
      border-top-left-radius: calc(8px - 1px);
      border-top-right-radius: calc(8px - 1px);
    }
  }
  .modal-title {
    color: $white;
    font-size: $font-size-18;
  }
  .close {
    padding: 0;
    margin: 0;
    color: $white;
    font-size: $font-size-30;
    text-shadow: none;
    opacity: 0.7;
    outline: none;
    &:hover {
      opacity: 1 !important;
    }
  }
  .modal-body {
    padding: 18px 25px;
  }
  .theme-btn {
    border: none;
  }
  .modal-dialog {
    @include transform(scale(.8) translate(0, 0));
  }
  &.show{
    display: block;
    .modal-dialog {
      @include transform(scale(1) translate(0, 0));
    }
  }
}
.mh-bg {
  background-image: url("../images/login-bg.jpg");
}
.mh-bg-2 {
  background-image: url("../images/signup-bg.jpg");
}
.user-action-meta {
  .lost-pass-btn {
    color: $theme-color-4;
    @include transition(0.3s);
    font-weight: $font-weight-medium;
    &:hover {
      color: $theme-color-2;
    }
  }
}

/*====== copy-to-clipboard =======*/
.copy-to-clipboard {
  position: relative;
  z-index: 1;
}
/*====== text-success-message=======*/
.text-success-message {
  background-color: $theme-color-5;
  color: $white;
  @include border-radius(4px);
  padding: 3px 6px;
  position: absolute;
  top: -10px;
  left: 50%;
  @include transition(0.4s);
  font-size: $font-size-15;
  opacity: 0;
  visibility: hidden;
  @include transform(translateX(-50%));
  font-weight: $font-weight-medium;
  &.active {
    top: -40px;
    opacity: 1;
    visibility: visible;
  }
  &:after {
    position: absolute;
    content: "";
    bottom: -4px;
    left: 50%;
    @include transform(translateX(-50%) rotate(45deg));
    background-color: $theme-color-5;
    width: 8px;
    height: 8px;
  }
}

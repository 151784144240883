* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
a:hover,
a:focus{
  text-decoration: none;
}
input:focus,
button:focus,
.dropdown-item:focus{
  outline: none;
}
h1, h2, h3, h4, h5, h6 {
  color: $theme-color;
  font-family: 'Route 159 Bold';
}
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}
img {
  opacity: 1;
  transition: 0.8s;
}

img[data-img] {
  opacity: 0;
}
body {
  font-size: $font-size-16;
  line-height: 24px;
  font-family: 'Be Vietnam', 'sans-serif';
  color: $theme-color-4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.font-be-vietnam {
  font-family: 'Be Vietnam', 'sans-serif' !important;
}
.font-Route {
  font-family: 'Route 159 Bold' !important;
}
/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Route 159 Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Route 159 Regular'), url('../fonts/Route159-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Route 159 UltraLight';
  font-style: normal;
  font-weight: normal;
  src: local('Route 159 UltraLight'), url('../fonts/Route159-UltraLight.woff') format('woff');
}
@font-face {
  font-family: 'Route 159 Light';
  font-style: normal;
  font-weight: normal;
  src: local('Route 159 Light'), url('../fonts/Route159-Light.woff') format('woff');
}
@font-face {
  font-family: 'Route 159 SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Route 159 SemiBold'), url('../fonts/Route159-SemiBold.woff') format('woff');
}
@font-face {
  font-family: 'Route 159 Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Route 159 Bold'), url('../fonts/Route159-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Route 159 Heavy';
  font-style: normal;
  font-weight: normal;
  src: local('Route 159 Heavy'), url('../fonts/Route159-Heavy.woff') format('woff');
}
/*======= scrollbar-hidden ======*/
.scrollbar-hidden {
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}
/*======= spacing ======*/
.section-padding {
  padding-top: 100px;
  padding-bottom: 100px;
}
.section--padding {
  padding-top: 100px;
  padding-bottom: 70px;
}
.section--margin {
  margin-top: 100px;
  margin-bottom: 100px;
}
.section--margin {
  margin-top: 100px;
  margin-bottom: 70px;
}

/* padding top */
.padding-top-320px {
  padding-top: 320px !important;
}
.padding-top-200px {
  padding-top: 200px !important;
}
.padding-top-190px {
  padding-top: 190px !important;
}
.padding-top-180px {
  padding-top: 180px !important;
}
.padding-top-170px {
  padding-top: 170px !important;
}
.padding-top-160px {
  padding-top: 160px !important;
}
.padding-top-150px {
  padding-top: 150px !important;
}
.padding-top-140px {
  padding-top: 140px !important;
}
.padding-top-130px {
  padding-top: 130px !important;
}
.padding-top-120px {
   padding-top: 120px !important;
 }
.padding-top-110px {
  padding-top: 110px !important;
}
.padding-top-100px {
  padding-top: 100px !important;
}
.padding-top-95px {
  padding-top: 95px !important;
}
.padding-top-90px {
  padding-top: 90px !important;
}
.padding-top-85px {
  padding-top: 85px !important;
}
.padding-top-80px {
  padding-top: 80px !important;
}
.padding-top-70px {
  padding-top: 70px !important;
}
.padding-top-60px {
  padding-top: 60px !important;
}
.padding-top-50px {
  padding-top: 50px !important;
}
.padding-top-50px {
  padding-top: 50px !important;
}
.padding-top-45px {
  padding-top: 45px !important;
}
.padding-top-40px {
  padding-top: 40px !important;
}
.padding-top-35px {
  padding-top: 35px !important;
}
.padding-top-30px {
  padding-top: 30px !important;
}
.padding-top-25px {
  padding-top: 25px !important;
}
.padding-top-20px {
  padding-top: 20px !important;
}
.padding-top-10px {
  padding-top: 10px !important;
}
/* padding bottom */
.padding-bottom-200px {
  padding-bottom: 200px !important;
}
.padding-bottom-190px {
  padding-bottom: 190px !important;
}
.padding-bottom-180px {
  padding-bottom: 180px !important;
}
.padding-bottom-170px {
  padding-bottom: 170px !important;
}
.padding-bottom-160px {
  padding-bottom: 160px !important;
}
.padding-bottom-150px {
  padding-bottom: 150px !important;
}
.padding-bottom-140px {
  padding-bottom: 140px !important;
}
.padding-bottom-130px {
  padding-bottom: 130px !important;
}
.padding-bottom-120px {
  padding-bottom: 120px !important;
}
.padding-bottom-110px {
  padding-bottom: 110px !important;
}
.padding-bottom-100px {
  padding-bottom: 100px !important;
}
.padding-bottom-95px {
  padding-bottom: 95px !important;
}
.padding-bottom-90px {
  padding-bottom: 90px !important;
}
.padding-bottom-85px {
  padding-bottom: 85px !important;
}
.padding-bottom-80px {
  padding-bottom: 80px !important;
}
.padding-bottom-70px {
  padding-bottom: 70px !important;
}
.padding-bottom-60px {
  padding-bottom: 60px !important;
}
.padding-bottom-50px {
  padding-bottom: 50px !important;
}
.padding-bottom-45px {
  padding-bottom: 45px !important;
}
.padding-bottom-40px {
  padding-bottom: 40px !important;
}
.padding-bottom-35px {
  padding-bottom: 35px !important;
}
.padding-bottom-30px {
  padding-bottom: 30px !important;
}
.padding-bottom-25px {
  padding-bottom: 25px !important;
}
.padding-bottom-20px {
  padding-bottom: 20px !important;
}
.padding-bottom-10px {
  padding-bottom: 10px !important;
}
/* padding right */
.padding-right-100px {
  padding-right: 100px !important;
}
.padding-right-95px {
  padding-right: 95px !important;
}
.padding-right-90px {
  padding-right: 90px !important;
}
.padding-right-85px {
  padding-right: 85px !important;
}
.padding-right-80px {
  padding-right: 80px !important;
}
.padding-right-70px {
  padding-right: 70px !important;
}
.padding-right-60px {
  padding-right: 60px !important;
}
.padding-right-55px {
  padding-right: 55px !important;
}
.padding-right-50px {
  padding-right: 50px !important;
}
.padding-right-45px {
  padding-right: 45px !important;
}
.padding-right-40px {
  padding-right: 40px !important;
}
.padding-right-30px {
  padding-right: 30px !important;
}
.padding-right-20px {
  padding-right: 20px !important;
}
.padding-right-25px {
  padding-right: 25px !important;
}
.padding-right-15px {
  padding-right: 15px !important;
}
.padding-right-10px {
  padding-right: 10px !important;
}
/* padding left */
.padding-left-100px {
  padding-left: 100px !important;
}
.padding-left-95px {
  padding-left: 95px !important;
}
.padding-left-90px {
  padding-left: 90px !important;
}
.padding-left-85px {
  padding-left: 85px !important;
}
.padding-left-80px {
  padding-left: 80px !important;
}
.padding-left-70px {
  padding-left: 70px !important;
}
.padding-left-60px {
  padding-left: 60px !important;
}
.padding-left-55px {
  padding-left: 55px !important;
}
.padding-left-50px {
  padding-left: 50px !important;
}
.padding-left-45px {
  padding-left: 45px !important;
}
.padding-left-40px {
  padding-left: 40px !important;
}
.padding-left-30px {
  padding-left: 30px !important;
}
.padding-left-25px {
  padding-left: 25px !important;
}
.padding-left-20px {
  padding-left: 20px !important;
}
.padding-left-15px {
  padding-left: 15px !important;
}
.padding-left-10px {
  padding-left: 10px !important;
}

/*===== margin top ====*/
.margin-top-200px {
  margin-top: 200px !important;
}
.margin-top-190px {
  margin-top: 190px !important;
}
.margin-top-180px {
  margin-top: 180px !important;
}
.margin-top-160px {
  margin-top: 160px !important;
}
.margin-top-150px {
  margin-top: 150px !important;
}
.margin-top-140px {
  margin-top: 140px !important;
}
.margin-top-130px {
  margin-top: 130px !important;
}
.margin-top-120px {
  margin-top: 120px !important;
}
.margin-top-110px {
  margin-top: 110px !important;
}
.margin-top-100px {
  margin-top: 100px !important;
}
.margin-top-95px {
  margin-top: 95px !important;
}
.margin-top-90px {
  margin-top: 90px !important;
}
.margin-top-85px {
  margin-top: 85px !important;
}
.margin-top-80px {
  margin-top: 80px !important;
}
.margin-top-70px {
  margin-top: 70px !important;
}
.margin-top-60px {
  margin-top: 60px !important;
}
.margin-top-55px {
  margin-top: 55px !important;
}
.margin-top-50px {
  margin-top: 50px !important;
}
.margin-top-45px {
  margin-top: 45px !important;
}
.margin-top-40px {
  margin-top: 40px !important;
}
.margin-top-35px {
  margin-top: 35px !important;
}
.margin-top-30px {
  margin-top: 30px !important;
}
.margin-top-25px {
  margin-top: 25px !important;
}
.margin-top-20px {
  margin-top: 20px !important;
}
.margin-top-10px {
  margin-top: 10px !important;
}
/*===== margin bottom ====*/
.margin-bottom-200px {
  margin-bottom: 200px !important;
}
.margin-bottom-190px {
  margin-bottom: 190px !important;
}
.margin-bottom-180px {
  margin-bottom: 180px !important;
}
.margin-bottom-170px {
  margin-bottom: 170px !important;
}
.margin-bottom-160px {
  margin-bottom: 160px !important;
}
.margin-bottom-150px {
  margin-bottom: 150px !important;
}
.margin-bottom-140px {
  margin-bottom: 140px !important;
}
.margin-bottom-130px {
  margin-bottom: 130px !important;
}
.margin-bottom-120px {
  margin-bottom: 120px !important;
}
.margin-bottom-110px {
  margin-bottom: 110px !important;
}
.margin-bottom-100px {
  margin-bottom: 100px !important;
}
.margin-bottom-95px {
  margin-bottom: 95px !important;
}
.margin-bottom-90px {
  margin-bottom: 90px !important;
}
.margin-bottom-85px {
  margin-bottom: 85px !important;
}
.margin-bottom-80px {
  margin-bottom: 80px !important;
}
.margin-bottom-70px {
  margin-bottom: 70px !important;
}
.margin-bottom-60px {
  margin-bottom: 60px !important;
}
.margin-bottom-50px {
  margin-bottom: 50px !important;
}
.margin-bottom-55px {
  margin-bottom: 55px !important;
}
.margin-bottom-45px {
  margin-bottom: 45px !important;
}
.margin-bottom-40px {
  margin-bottom: 40px !important;
}
.margin-bottom-35px {
  margin-bottom: 35px !important;
}
.margin-bottom-30px {
  margin-bottom: 30px !important;
}
.margin-bottom-25px {
  margin-bottom: 25px !important;
}
.margin-bottom-20px {
  margin-bottom: 20px !important;
}
.margin-bottom-10px {
  margin-bottom: 10px !important;
}

/*---------------- bg ----------------*/
.bg-white {
  background-color: $white !important;
}
.bg-dark {
  background-color: $theme-color !important;
}
.bg-gray {
  background-color: $gray !important;
}
.bg-gradient-gray {
  background: linear-gradient(70deg,#e8eef9 0,#fff 100%) !important;
}
.bg-transparent {
  background-image: none !important;
}
.bg-dark-opacity {
  background-color: rgba(11, 11, 11, 0.1) !important;
}
.bg-fixed {
  background-attachment: fixed !important;
}
/*===== gradient bg ======*/
.bg-gradient-1 {
  background-image: linear-gradient(45deg, #5065cd 0%, #c366bd 100%) !important;
}
.bg-gradient-2 {
  background-image: linear-gradient(62deg, #d6875a 0%, #F7CE68 100%) !important;
}
.bg-gradient-3 {
  background-image: linear-gradient(45deg, #0877b9 0%, #80D0C7 100%) !important;
}
.bg-gradient-4 {
  background-image: linear-gradient(45deg, #31d0f5 0%, #2c61e6 100%) !important;
}

.bg-1 {
  background-color: $color-3 !important;
}
.bg-2 {
  background-color: $color-4 !important;
}
.bg-3 {
  background-color: $color-5 !important;
}
.bg-4 {
  background-color: $color-6 !important;
}
.bg-5 {
  background-color: $color-7 !important;
}
.bg-6 {
  background-color: $color-8 !important;
}
.bg-7 {
  background-color: $color-9 !important;
}
.bg-8 {
  background-color: $color-10 !important;
}
.bg-9 {
  background-color: $color-11 !important;
}
.bg-10 {
  background-color: $theme-color-2 !important;
}

.bg-opacity-1 {
  background: rgba(140, 67, 255, 0.1) !important;
}
.bg-opacity-2 {
  background: rgba(40, 213, 167, 0.1) !important;
}
.bg-opacity-3 {
  background: rgba(249, 184, 81, 0.1) !important;
}
.bg-opacity-4 {
  background: rgba(204, 8, 233, 0.1) !important;
}

.f-bg {
  background-color: $color-10 !important;
}
.t-bg {
  background-color: $color-11 !important;
}
.i-bg {
  background-color: $color-12 !important;
}
.y-bg {
  background-color: $color-17 !important;
}

.f-bg-rgb {
  background-color: rgba(66, 103, 178, 0.1) !important;
  color: $color-10 !important;
}
.t-bg-rgb {
  background-color: rgba(113, 201, 248, 0.1) !important;
  color: $color-11 !important;
}
.i-bg-rgb {
  background-color: rgba(193, 53, 172, 0.1) !important;
  color: $color-12 !important;
}
.y-bg-rgb {
  background-color: rgba(230, 33, 23, 0.1) !important;
  color: $color-17 !important;
}


.bg-rgb-danger {
  background-color: rgba(220, 53, 69, 0.1) !important;
  color: #dc3545 !important;
  &:hover {
    background-color: rgba(220, 53, 69, 0.2) !important;
  }
}
.bg-rgb-success {
  background-color: rgba(40, 167, 69, 0.1) !important;
  color: #28a745 !important;
  &:hover {
    background-color: rgba(40, 167, 69, 0.2) !important;
  }
}

.svg-icon-color {
  path {
    fill: $color-3;
  }
}
.svg-icon-color-2 {
  path {
    fill: $color-4;
  }
}
.svg-icon-color-3 {
  path {
    fill: $color-5;
  }
}
.svg-icon-color-4 {
  path {
    fill: $color-6;
  }
}
.svg-icon-white {
  path {
    fill: $white;
  }
}
/*---------------- font sizes ----------------*/
.font-size-100 {
  font-size: 100px !important;
}
.font-size-95 {
  font-size: 95px !important;
}
.font-size-90 {
  font-size: 90px !important;
}
.font-size-85 {
  font-size: 85px !important;
}
.font-size-80 {
  font-size: 80px !important;
}
.font-size-75 {
  font-size: 75px !important;
}
.font-size-70 {
  font-size: 70px !important;
}
.font-size-65 {
  font-size: 65px !important;
}
.font-size-60 {
  font-size: 60px !important;
}
.font-size-55 {
  font-size: 55px !important;
}
.font-size-50 {
  font-size: 50px !important;
}
.font-size-45 {
  font-size: 45px !important;
}
.font-size-40 {
  font-size: 40px !important;
}
.font-size-35 {
  font-size: 35px !important;
}
.font-size-30 {
  font-size: 30px !important;
}
.font-size-28 {
  font-size: 28px !important;
}
.font-size-27 {
  font-size: 27px !important;
}
.font-size-26 {
  font-size: 26px !important;
}
.font-size-25 {
  font-size: 25px !important;
}
.font-size-24 {
  font-size: 24px !important;
}
.font-size-23 {
  font-size: 23px !important;
}
.font-size-22 {
  font-size: 22px !important;
}
.font-size-21 {
  font-size: 21px !important;
}
.font-size-20 {
  font-size: 20px !important;
}
.font-size-19 {
  font-size: 19px !important;
}
.font-size-18 {
  font-size: 18px !important;
}
.font-size-17 {
  font-size: 17px !important;
}
.font-size-16 {
  font-size: 16px !important;
}
.font-size-15 {
  font-size: 15px !important;
}
.font-size-14 {
  font-size: 14px !important;
}
.font-size-13 {
  font-size: 13px !important;
}
.font-size-12 {
  font-size: 12px !important;
}
.font-size-11 {
  font-size: 11px !important;
}
/*---------------- line-height ----------------*/
.line-height-100 {
  line-height: 100px !important;
}
.line-height-95 {
  line-height: 95px !important;
}
.line-height-90 {
  line-height: 90px !important;
}
.line-height-85 {
  line-height: 85px !important;
}
.line-height-80 {
  line-height: 80px !important;
}
.line-height-75 {
  line-height: 75px !important;
}
.line-height-70 {
  line-height: 70px !important;
}
.line-height-65 {
  line-height: 65px !important;
}
.line-height-60 {
  line-height: 60px !important;
}
.line-height-55 {
  line-height: 55px !important;
}
.line-height-50 {
  line-height: 50px !important;
}
.line-height-45 {
  line-height: 45px !important;
}
.line-height-40 {
  line-height: 40px !important;
}
.line-height-35 {
  line-height: 35px !important;
}
.line-height-30 {
  line-height: 30px !important;
}
.line-height-28 {
  line-height: 28px !important;
}
.line-height-27 {
  line-height: 27px !important;
}
.line-height-26 {
  line-height: 26px !important;
}
.line-height-25 {
  line-height: 25px !important;
}
.line-height-24 {
  line-height: 24px !important;
}
.line-height-23 {
  line-height: 23px !important;
}
.line-height-22 {
  line-height: 22px !important;
}
.line-height-21 {
  line-height: 21px !important;
}
.line-height-20 {
  line-height: 20px !important;
}
.line-height-18 {
  line-height: 18px !important;
}
.line-height-16 {
  line-height: 16px !important;
}
.line-height-15 {
  line-height: 15px !important;
}
/*---------------- font-weight ----------------*/
.font-weight-thin {
  font-weight: $font-weight-thin !important;
}
.font-weight-light {
  font-weight: $font-weight-light !important;
}
.font-weight-regular {
  font-weight: $font-weight-regular !important;
}
.font-weight-medium {
  font-weight: $font-weight-medium !important;
}
.font-weight-semi-bold {
  font-weight: $font-weight-semi-bold !important;
}
.font-weight-bold {
  font-weight: $font-weight-bold !important;
}
/*=== opacity ====*/
.opacity-1 {
  opacity: 0.1 !important;
}
.opacity-4 {
  opacity: 0.4 !important;
}
.opacity-5 {
  opacity: 0.5 !important;
}
.opacity-9 {
  opacity: 0.9 !important;
}
/*=== radius ====*/
.radius-round {
  @include border-radius(4px !important);
}
.radius-rounded {
  @include border-radius(30px !important);
}
/*=== before after ====*/
.ba-none {
  &:before,
  &:after {
    display: none !important;
  }
}
.before-none:before {
  display: none !important;
}
.after-none:after {
  display: none !important;
}
/*=== section-block ====*/
.section-block,
.section-block-2{
  height: 1px;
}
.section-block {
  background-color: $gray;
}
.section-block-2 {
  background-color: rgba(127, 137, 150, 0.1);
}
/*=== border color ====*/
.border-color {
  border-color: rgba(127, 137, 150, 0.1) !important;
}
.border-top-color {
  border-top-color: rgba(127, 137, 150, 0.1) !important;
}
.border-right-color {
  border-right-color: rgba(127, 137, 150, 0.1) !important;
}
.border-bottom-color {
  border-bottom-color: rgba(127, 137, 150, 0.1) !important;
}
.border-left-color {
  border-left-color: rgba(127, 137, 150, 0.1) !important;
}
/*=== text color ====*/
.text-gray {
  color: $theme-color-4 !important;
}
.text-color {
  color: $theme-color !important;
}
.text-color-2 {
  color: $theme-color-2 !important;
}
.text-color-3 {
  color: $color-3 !important;
}
.text-color-4 {
  color: $color-4 !important;
}
.text-color-5 {
  color: $color-5 !important;
}
.text-color-6 {
  color: $color-6 !important;
}
.text-color-7 {
  color: $color-7 !important;
}
.text-color-8 {
  color: $color-8 !important;
}
.text-color-9 {
  color: $color-9 !important;
}
.text-color-10 {
  color: $color-10 !important;
}
.text-color-11 {
  color: $color-11 !important;
}
.text-color-12 {
  color: $color-12 !important;
}
.text-color-13 {
  color: $color-13 !important;
}
.text-color-14 {
  color: $color-14 !important;
}
.text-color-15 {
  color: $color-15 !important;
}
.text-color-16 {
  color: $color-16 !important;
}

/*=== p-relative p-absolute ====*/
.p-relative {
  position: relative !important;
}
.p-absolute {
  position: absolute !important;
}
.position-inherit {
  position: inherit !important;
}

.top-auto {
  top: auto !important;
}
.right-auto {
  right: auto !important;
}
.bottom-auto {
  bottom: auto !important;
}
.left-auto {
  left: auto !important;
}
.top-0 {
  top: 0 !important;
}
.right-0 {
  right: 0 !important;
}
.bottom-0 {
  bottom: 0 !important;
}
.left-0 {
  left: 0 !important;
}

.bounce {
  animation: bounce 8s ease-in-out infinite;
}
.z-index-1 {
  z-index: 1;
}
.z-index-2 {
  z-index: 2;
}
.z-index-3 {
  z-index: 3;
}
.z-index-4 {
  z-index: 4;
}
.z-index-5 {
  z-index: 5;
}
.z-index-negative {
  z-index: -1;
}
.flex-column-inherit {
  flex-direction: inherit !important;
}
/*---------------- container ----------------*/
.container {
  max-width: 1200px;
}

@media (min-width:576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width:768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width:992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width:1200px) {
  .container {
    max-width: 1200px;
  }
}

.responsive-text-center {
  @media #{$tab_device} {
   text-align: center !important;
  }
  @media #{$large_mobile} {
   text-align: center !important;
  }
  @media #{$small_mobile} {
   text-align: center !important;
  }
}
.responsive-text-left {
  @media #{$tab_device} {
   text-align: left !important;
  }
  @media #{$large_mobile} {
   text-align: left !important;
  }
  @media #{$small_mobile} {
   text-align: left !important;
  }
}
.responsive-text-right {
  @media #{$tab_device} {
   text-align: right !important;
  }
  @media #{$large_mobile} {
   text-align: right !important;
  }
  @media #{$small_mobile} {
   text-align: right !important;
  }
}

.responsive-column {
  @media #{$tab_device} {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media #{$large_mobile} {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media #{$small_mobile} {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.responsive-column-lg {
  @media #{$laptop_m} {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media #{$laptop_m_four} {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media #{$large_mobile} {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media #{$small_mobile} {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.responsive-column--lg {
  @media #{$laptop_m_four} {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
/* info-box */
.info-box {
  background-color: $white;
  padding: 40px;
  @include border-radius(8px);
  @include box-shadow(0 10px 40px rgba(82, 85, 90, 0.1));
  @include transition(0.3s);
  margin-bottom: 30px;
  position: relative;
  text-align: center;
  z-index: 1;
  .info-icon {
    width: 85px;
    height: 85px;
    line-height: 85px;
    @include border-radius(59% 41% 60% 40% / 46% 66% 34% 54%  );
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    background-color: $theme-color-2;
    color: $white;
    text-align: center;
    font-size: $primary-font;
    @include box-shadow(0 5px 25px rgba(123,104,238,.3));
    @include transition(0.3s);
  }
  .info-number {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 33px;
    height: 33px;
    line-height: 30px;
    color: $theme-color-2;
    background-color: rgba(123, 104, 238, 0.1);
    @include border-radius(50%);
    @include transition(0.3s);
    font-weight: $font-weight-medium;
    font-size: $font-size-16;
  }
  .info__title {
    font-size: $font-size-20;
    color: $theme-color;
    font-weight: $font-weight-semi-bold;
    margin-bottom: 20px;
  }
  .info__desc {
    font-size: $font-size-16;
    color: $theme-color-4;
    font-weight: $font-weight-medium;
  }
  &:hover {
    @include transform(translateY(-4px));
    .info-icon,
    .info-number {
      background-color: $theme-color-3;
    }
    .info-number {
      color: $white;
    }
  }
}
/* info--box */
.info--box {
  border: 1px solid rgba(127, 137, 150, 0.1);
  .info-icon {
    @include box-shadow(none);
    background: linear-gradient(to right, rgba(255, 201, 196, 0.15), rgba(255, 222, 162, 0.15));
    margin-bottom: 25px;
  }
  &:hover {
    .info-icon {
      background: linear-gradient(to right, rgba(255, 201, 196, 0.15), rgba(255, 222, 162, 0.15));
    }
  }
}
/* info-box-layout-2 */
.info-box-layout-2 {
  .info-icon {
    @include box-shadow(0 5px 25px rgba(0,0,0,.2));
  }
  &:hover {
    @include transform(translateY(0) scale(1.02));
  }
}

.info-box-layout-2,
.info-box-layout-3 {
  text-align: left;
  .info-icon {
    margin-left: 0;
    @include border-radius(50%);
    width: 65px;
    height: 65px;
    line-height: 65px;
    font-size: $font-size-30;
  }
}
/* info-box-layout-3 */
.info-box-layout-3 {
  @include border-radius(0);
  @include box-shadow(none);
  padding: 0 20px 0 0;
  .info-icon {
    @include box-shadow(0 5px 25px rgba(0,0,0,.1));
  }
  &:hover {
    @include transform(translateY(0));
  }
}

.info-box-line-bg {
  .info-box {
    &:after {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      width: 180px;
      height: 68px;
      background-image: url("../images/dashed-line.png");
      background-repeat: no-repeat;
      opacity: 0.1;
    }
  }
  .col-lg-4 {
    &:nth-child(2) {
      .info-box {
        &:after {
          background-image: url("../images/dashed-line-2.png");
        }
      }
    }
    &:last-child {
      .info-box {
        &:after {
          display: none;
        }
      }
    }
  }
}